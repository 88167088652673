<template>
  <div v-if="editor" class="wysiwyg">
    <nav class="wysiwyg__nav">
      <template v-for="(button, idx) in buttons" :key="idx">
        <div v-if="button.separator" class="separator"></div>
        <button v-else type="button"
              class="button button--icon button--grey"
              :class="{ 'is-active': button.active() }"
              :aria-label="button.label"
              :title="button.label"
              @click="button.click()">
          <inline-svg :src="require(`@/assets/icons/wysiwyg/${button.icon}`)"/>
        </button>
      </template>
    </nav>
    <editor-content :editor="editor"/>
    <input type="hidden" :name="name" :id="id" :value="content" ref="field"/>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'

export default {
  props: {
    name: {
      type: String,
    },
    id: {
      type: String,
    },
    value: {
      type: String,
      default: '',
    },
  },
  components: {
    EditorContent,
  },
  data() {
    return {
      content: this.value,
      editor: null,
      buttons: [
        {
          label: 'Bold',
          icon: 'bold.svg',
          click: () => this.editor.chain().focus().toggleBold().run(),
          active: () => this.editor.isActive('bold'),
        },
        {
          label: 'Italic',
          icon: 'italic.svg',
          click: () => this.editor.chain().focus().toggleItalic().run(),
          active: () => this.editor.isActive('italic'),
        },
        {
          label: 'Strike',
          icon: 'strikethrough.svg',
          click: () => this.editor.chain().focus().toggleStrike().run(),
          active: () => this.editor.isActive('strike'),
        },
        {
          separator: true,
        },
        {
          label: 'Heading 2',
          icon: 'heading2.svg',
          click: () => this.editor.chain().focus().toggleHeading({ level: 2 }).run(),
          active: () => this.editor.isActive('heading', { level: 2 }),
        },
        {
          label: 'Heading 3',
          icon: 'heading3.svg',
          click: () => this.editor.chain().focus().toggleHeading({ level: 3 }).run(),
          active: () => this.editor.isActive('heading', { level: 3 }),
        },
        {
          label: 'Heading 4',
          icon: 'heading4.svg',
          click: () => this.editor.chain().focus().toggleHeading({ level: 4 }).run(),
          active: () => this.editor.isActive('heading', { level: 4 }),
        },
        {
          label: 'Paragraph',
          icon: 'paragraph.svg',
          click: () => this.editor.chain().focus().setParagraph().run(),
          active: () => this.editor.isActive('paragraph'),
        },
        {
          separator: true,
        },
        {
          label: 'Bullet List',
          icon: 'unorderedlist.svg',
          click: () => this.editor.chain().focus().toggleBulletList().run(),
          active: () => this.editor.isActive('bulletList'),
        },
        {
          label: 'Ordered List',
          icon: 'orderedlist.svg',
          click: () => this.editor.chain().focus().toggleOrderedList().run(),
          active: () => this.editor.isActive('orderedList'),
        },
      ],
    };
  },
  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
      ],
      onUpdate: () => {
        // check if we have any text, so we don't pass empty tags to the API.
        const isEmpty = this.editor.state.doc.textContent.length === 0;
        if (isEmpty) {
          this.content = '';
        } else {
          this.content = this.editor.getHTML();
        }
      },
    });
  },
  beforeUnmount() {
    this.editor.destroy();
  },
  watch: {
    value(value) {
      this.content = value;
      this.editor.commands.setContent(value);
    },
  },
}
</script>