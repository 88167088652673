<template>
  <modal :id="id" @close="modalClose">
    <template #title>
      <slot name="title"/>
    </template>
    <template #content>
      <form :id="`modal-form-${id}`" ref="form" @submit.prevent="submit">
        <fieldset ref="fields" :disabled="loading">
          <slot name="fields"/>
        </fieldset>
      </form>
      <div v-if="error" class="notification notification--error mt-3">
        <h5 class="notification__title">Error</h5>
        <span class="notification__error">There was an error, try again later.<br/><br/>{{ error }}</span>
      </div>
    </template>
    <template #footer>
      <div ref="buttons" class="form-group ajax-modal-form-buttons">
        <button type="submit" ref="submit" :form="`modal-form-${id}`" class="button button--loader" :class="[buttonClass, { 'loading': loading }]">
          <inline-svg :src="require('@/assets/spinner.svg')" stroke="currentColor" width="16" height="16" class="preloader"/>
          <span>{{ buttonText }}</span>
        </button>
        <slot name="buttons"/>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/modal.vue'
import { httpPost, httpDelete } from '@/http'
import { duplicateFields } from '@/utils'

export default {
  emits: ['post', 'done', 'error', 'close'],
  props: {
    /**
     * Unique ID of the modal
     */
    id: {
      type: String,
      required: true,
    },
    /**
     * Type of action for HTTP request (post, delete)
     */
    action: {
      type: String,
      default: 'post',
      validator: value => value === 'post' || value === 'delete',
    },
    /**
     * URL to post to when form is submit
     */
    url: {
      type: String,
      required: true,
    },
    /**
     * Text to show in the primary submit button of the form
     */
    buttonText: {
      type: String,
      default: 'Submit',
    },
    /**
     * Extra classes for primary submit button
     */
    buttonClass: {
      type: String,
      required: false,
    },
    /**
     * Boolean to toggle modal close when ajax request is finished
     */
    closeOnDone: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      controller: null,
      loading: false,
      error: null,
      duplicated: [],
    };
  },
  beforeUnmount() {
    this.abort();
    this.destroyDuplicateFields();
  },
  methods: {
    abort() {
      this.controller?.abort();
      this.loading = false;
      this.error = null;
      this.controller = null;
    },
    modalClose() {
      // TODO : only reset if we change modal
      this.$refs.form.reset();
      this.abort();
      this.destroyDuplicateFields();
      this.$emit('close');
    },
    destroyDuplicateFields() {
      for (const { el, cloned } of this.duplicated) {
        cloned.remove();
        el.style.display = null;
      }

      this.duplicated = [];
    },
    submit(formData = null) {
      // if formData is not a FormData object, it probably means the submit method was called from the
      // form submit handler
      const submitViaForm = !(formData instanceof FormData);

      if (submitViaForm) {
        formData = new FormData(this.$refs.form);
      }

      this.duplicated = duplicateFields(formData, this.$refs.form);

      this.controller = new AbortController();
      this.loading = true;
      this.$refs.buttons.classList.remove('invalid');

      this.$emit('post', formData);

      // get correct method
      let method = null;
      if (this.action === 'post') method = httpPost;
      else if (this.action === 'delete') method = httpDelete;

      method(this.url, formData, { signal: this.controller.signal })
        .then((res) => {
          if (submitViaForm && this.closeOnDone) {
            this.closeModal(this.id);
            this.$refs.form.reset();
          }

          this.$emit('done', { result: res, formData });
        })
        .catch((err) => {
          this.error = err.message;
          this.$refs.buttons.classList.add('invalid');
          this.$emit('error', this.error);
        })
        .finally(() => {
          this.loading = false;
          this.destroyDuplicateFields();
        });
    },
  },
}
</script>