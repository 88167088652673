<template>
  <promise invoke="getJob" :args="{ id: $route.params.id }" :key="$route.params.id" @ready="updateTitle">
    <template #content="props">
      <ajax-modal id="create-magic-link" url="/magiclink/new" buttonText="Create" @done="magicLinkCreated">
        <template #title>Create Magic Link</template>
        <template #fields>
          <p class="mt-0">Create a magic link which can be used to send to the client so they can view the project page without manually logging in.</p>
          <p>The magic link will be automatically copied to your clipboard.</p>
          <input type="hidden" name="url" :value="`/client-project/${$route.params.id}`"/>
          <div class="form-group mt-3">
            <label for="magic-link-user">User <abbr class="required">*</abbr> <span class="desc">Select a Contact which the magic link will be valid for.</span></label>
            <select name="client_contact_uuid" id="magic-link-user" class="form-select" required>
              <option value="" selected disabled>Select a Contact</option>
              <option v-for="contact in props.data.allClientContacts"
                      :key="contact.contactUUID"
                      :value="contact.contactUUID">{{ contact.name }} ({{ contact.email }})</option>
            </select>
          </div>
        </template>
      </ajax-modal>
      <header class="main-header">
        <div class="row align-items-center">
          <div class="col-8">
            <div class="row no-gutters align-items-center">
              <div class="col-auto">
                <Avatar :string="props.data.client.name" class="userinfo__image-container--xl"/>
              </div>
              <div class="col-auto">
                <h1 class="main-header__title"><span>{{ props.data.jobRef }}</span> &mdash; {{ props.data.jobName }}</h1>
                <h2 class="main-header__subtitle">
                  <router-link :to="`/client/${props.data.client.uuid}`">{{ props.data.client.name }}</router-link>
                </h2>
              </div>
            </div>
          </div>
          <div class="col-4 d-flex flex-column align-items-end">
            <a :href="props.data.wfmJobUrl" target="_blank" rel="noopener" class="button button--sm">Open in WorkflowMax</a>
            <!--<button class="button button--grey button--sm button--icon mt-1">
              <inline-svg :src="require('@/assets/icons/clipboard.svg')" stroke="currentColor" width="14" height="14"/>
              <span>Create magic link for Client</span>
            </button>-->
            <button class="button button--grey button--sm button--loader mt-1" v-open-modal="'create-magic-link'">
              <inline-svg :src="require('@/assets/spinner.svg')" stroke="currentColor" width="14" height="14" class="preloader"/>
              <span>Create magic link for Client</span>
            </button>
          </div>
        </div>
      </header>
      <section class="main-content">
        <!-- project update -->
        <div class="d-flex align-items-center mb-3">
          <div class="mr-4">
            <h3 class="mt-0 mb-0">Project Update</h3>
            <p v-if="props.data.projectUpdate_updated_at" class="heading-desc mt-0 mb-0">Last updated on <strong>{{ formatDate(props.data.projectUpdate_updated_at) }}</strong></p>
          </div>
          <button v-if="!editingProjectUpdate" class="button button--sm" @click="editingProjectUpdate = true">Edit</button>
        </div>
        <div v-if="!editingProjectUpdate">
          <div v-if="props.data.projectUpdate" class="project__update" v-html="props.data.projectUpdate"/>
          <p v-else>No project update has been added.</p>
        </div>
        <ajax-form v-else :url="`/job/${$route.params.id}`" buttonText="Update" @done="data => projectUpdateUpdated(props, data)">
          <template #fields>
            <div class="form-group">
              <wysiwyg name="projectUpdate" id="projectUpdate" :value="props.data.projectUpdate"/>
            </div>
          </template>
          <template #buttons>
            <button type="button" class="button button--grey ml-1" @click="editingProjectUpdate = false">Cancel</button>
          </template>
        </ajax-form>

        <div class="row mt-5">
          <div class="col-lg-7 mb-5 mb-lg-0">
            <documents endpoint="job" :documents="props.data.projectDocuments"
                           @add="data => projectDocumentAdded(props, data)"
                           @delete="document => projectDocumentDeleted(props, document)">
              <template #title>Project Documents</template>
              <template #modal-title>Add Project Document</template>
              <template #modal-desc>Add a project related document/url to share with the client.</template>
            </documents>
          </div>
          <div class="col-lg-5">
            <!-- key contacts -->
            <Contacts title="Project Client Contacts" :clientName="`${props.data.client.name} - ${props.data.jobName}`" :clientContacts="props.data.allClientContacts"/>
          </div>
        </div>

        <!-- milestones -->
        <!-- MODAL : add milestone -->
        <ajax-modal id="add-milestone" :url="`/job/${$route.params.id}/milestone/new`" buttonText="Add" @done="data => milestoneAdded(props, data)">
          <template #title>Add Milestone</template>
          <template #fields>
            <p class="mt-0">Add a milestone for the project which can include files/documents and share with the client.</p>
            <div class="form-group">
              <label for="title">Title <abbr class="required">*</abbr></label>
              <input type="text" id="title" name="title" class="form-control" placeholder="Title" required/>
            </div>
            <div class="form-group">
              <label for="description">Description <abbr class="required">*</abbr></label>
              <textarea id="description" name="description" rows="4" class="form-control" placeholder="Description" required></textarea>
            </div>
            <div class="form-group">
              <label for="due_date">Due Date <abbr class="required">*</abbr></label>
              <input type="date" id="due_date" name="due_date" class="form-control" required/>
            </div>
            <div class="form-group">
              <label for="requires_signoff" class="form-checkbox">
                <input type="checkbox" id="requires_signoff" name="requires_signoff"/>
                <span class="form-checkbox__label">Requires sign-off</span>
              </label>
            </div>
          </template>
        </ajax-modal>
        <!-- MODAL : delete milestone -->
        <ajax-modal v-if="deleteMilestoneContext" id="delete-milestone" action="delete" :url="`/job/${$route.params.id}/milestone/${deleteMilestoneContext.id}`" buttonText="Yes" buttonClass="button--red" @done="() => milestoneDeleted(props)">
          <template #title>Delete Milestone?</template>
          <template #fields>
            <p class="mt-0">You are about to permanently delete the milestone named <strong>{{ deleteMilestoneContext.title }}</strong><span v-if="deleteMilestoneContext.documents.length > 0"> and <strong>{{ deleteMilestoneContext.documents.length }}</strong> attached documents</span>.</p>
            <p>This action can not be undone, are you sure you want to continue?</p>
          </template>
        </ajax-modal>
        <!-- MODAL : add milestone document -->
        <ajax-modal v-if="addMilestoneDocumentContext" id="add-milestone-document" :url="`/job/${$route.params.id}/milestone/${addMilestoneDocumentContext.milestone.id}/document/new`" buttonText="Add" @post="transformCheckboxValues" @done="milestoneDocumentAdded">
          <template #title>Add Milestone Document</template>
          <template #fields>
            <p class="mt-0 mb-3">Add a file/document to attach to the milestone named <strong>{{ addMilestoneDocumentContext.milestone.title }}</strong> and share with the client.</p>
            <div class="form-group">
              <label for="document-title">Title <span class="desc">File name will be used if no title is provided.</span></label>
              <input type="text" name="title" id="document-title" class="form-control" placeholder="Title"/>
            </div>
            <div class="form-group">
              <label for="document-message">Message</label>
              <textarea id="document-message" name="chatter_document_message" rows="4" class="form-control" placeholder="Message"></textarea>
            </div>
            <div class="form-group">
              <label>Document type <abbr class="required">*</abbr></label>
              <div class="form-group" :key="addMilestoneDocumentContext.time">
                <label for="document-type-file" class="form-checkbox">
                  <input type="radio" name="document_type" id="document-type-file" class="form-checkbox" value="file" v-model="addMilestoneDocumentContext.type" required/>
                  <span class="form-checkbox__label">File Upload</span>
                </label>
                <label for="document-type-url" class="form-checkbox ml-3">
                  <input type="radio" name="document_type" id="document-type-url" class="form-checkbox" value="url" v-model="addMilestoneDocumentContext.type" required/>
                  <span class="form-checkbox__label">External URL</span>
                </label>
              </div>
            </div>
            <div v-if="addMilestoneDocumentContext.type === 'file'" class="form-group">
              <label for="document-file">File <abbr class="required">*</abbr></label>
              <input type="file" name="file" id="document-file" class="form-control" required/>
            </div>
            <div v-else-if="addMilestoneDocumentContext.type === 'url'" class="form-group">
              <label for="document-url">URL <abbr class="required">*</abbr></label>
              <input type="url" name="url" id="document-url" class="form-control" placeholder="URL" required/>
            </div>
            <div class="form-group">
              <label for="document-signoff" class="form-checkbox">
                <input type="checkbox" id="document-signoff" name="requires_signoff" v-model="addMilestoneDocumentContext.requiresSignoff"/>
                <span class="form-checkbox__label">Requires sign-off</span>
              </label>
            </div>
            <template v-if="addMilestoneDocumentContext.requiresSignoff">
              <div class="form-group">
                <label for="document-signoff-recipient">Sign-off Recipient <abbr class="required">*</abbr> <span class="desc">Select a Contact which will be asked to sign off the document.</span></label>
                <select name="signoff_client_contact_uuid" id="document-signoff-recipient" class="form-select" v-model="addMilestoneDocumentContext.signOffRecipient" required>
                  <option value="" selected disabled>Select a Contact</option>
                  <option v-for="contact in props.data.allClientContacts"
                          :key="contact.contactUUID"
                          :value="contact.contactUUID">{{ contact.name }} ({{ contact.email }})</option>
                </select>
              </div>
              <div v-if="props.data.allClientContacts.length > 1" class="form-group">
                <label for="document-signoff-cc">Sign-off CC <span class="desc">Select contacts which will also be notified of the document sign-off.</span></label>
                <select name="signoff_client_cc_uuids[]" id="document-signoff-cc" class="form-select" required multiple>
                  <option v-for="contact in filteredClientContacts(props.data.allClientContacts)"
                          :key="contact.contactUUID"
                          :value="contact.contactUUID">{{ contact.name }} ({{ contact.email }})</option>
                </select>
              </div>
              <div class="form-group">
                <label for="document-signoff-message">Sign-off Message <abbr class="required">*</abbr></label>
                <wysiwyg name="chatter_document_message" id="document-signoff-message"/>
              </div>
            </template>
          </template>
        </ajax-modal>
        <!-- MODAL : edit milestone document -->
        <ajax-modal v-if="editMilestoneDocumentContext" id="edit-milestone-document" :url="`/job/${$route.params.id}/milestone/${editMilestoneDocumentContext.milestone.id}/document/${editMilestoneDocumentContext.document.document_uuid}`" buttonText="Update" @post="transformCheckboxValues" @done="milestoneDocumentUpdated">
          <template #title>Edit Milestone Document</template>
          <template #fields>
            <p class="mt-0 mb-3">You are editing a milestone document named <strong>{{ editMilestoneDocumentContext.document.title }}</strong> attached to the milestone named <strong>{{ editMilestoneDocumentContext.milestone.title }}</strong>.</p>
            <div class="form-group">
              <label for="edit-document-title">Title <span class="desc">File name will be used if no title is provided.</span></label>
              <input type="text" id="edit-document-title" name="title" class="form-control" placeholder="Title" :value="editMilestoneDocumentContext.document.title"/>
            </div>
            <div v-if="editMilestoneDocumentContext.document.document_type === 'file'" class="form-group">
              <label for="edit-document-file">File <abbr class="required">*</abbr></label>
              <input type="text" id="edit-document-file" class="form-control" :value="editMilestoneDocumentContext.document.original_filename" disabled/>
            </div>
            <div v-else-if="editMilestoneDocumentContext.document.document_type === 'url'" class="form-group">
              <label for="edit-document-url">URL <abbr class="required">*</abbr></label>
              <input type="url" name="url" id="edit-document-url" class="form-control" placeholder="URL" :value="editMilestoneDocumentContext.document.url" required/>
            </div>
            <div class="form-group">
              <label for="edit-document-signoff" class="form-checkbox">
                <input type="checkbox" id="edit-document-signoff" name="requires_signoff" v-model="editMilestoneDocumentContext.document.requires_signoff" :true-value="1" :false-value="0"/>
                <span class="form-checkbox__label">Requires sign-off</span>
              </label>
            </div>
            <template v-if="editMilestoneDocumentContext.document.requires_signoff">
              <div class="form-group">
                <label for="edit-document-signoff-recipient">Sign-off Recipient <abbr class="required">*</abbr> <span class="desc">Select a Contact which will be asked to sign off the document.</span></label>
                <select name="signoff_client_contact_uuid" id="edit-document-signoff-recipient" class="form-select" required>
                  <option value="" :selected="!editMilestoneDocumentContext.document.signoff_client_contact_uuid" disabled>Select a Contact</option>
                  <option v-for="contact in props.data.allClientContacts"
                          :key="contact.contactUUID" :value="contact.contactUUID"
                          :selected="editMilestoneDocumentContext.document.signoff_client_contact_uuid === contact.contactUUID">{{ contact.name }} ({{ contact.email }})</option>
                </select>
              </div>
              <div class="form-group">
                <label for="edit-document-signoff-message">Sign-off Message <abbr class="required">*</abbr></label>
                <wysiwyg name="chatter_document_message" id="edit-document-signoff-message" :value="editMilestoneDocumentContext.document.chatter_document_message"/>
              </div>
            </template>
          </template>
        </ajax-modal>
        <!-- MODAL : delete milestone document -->
        <ajax-modal v-if="deleteMilestoneDocumentContext" id="delete-milestone-document" action="delete" :url="`/job/${$route.params.id}/milestone/${deleteMilestoneDocumentContext.milestone.id}/document/${deleteMilestoneDocumentContext.document.document_uuid}`" buttonText="Yes" buttonClass="button--red" @done="milestoneDocumentDeleted">
          <template #title>Delete Milestone Document?</template>
          <template #fields>
            <p class="mt-0">You are about to permanently delete the milestone document named <strong>{{ deleteMilestoneDocumentContext.document.title }}</strong>.</p>
            <p>This action can not be undone, are you sure you want to continue?</p>
          </template>
        </ajax-modal>
        <!-- milestone title -->
        <div class="d-flex align-items-center justify-content-between mt-5 mb-3">
          <h3 class="mt-0 mb-0">Project Milestones</h3>
          <button class="button button--sm" v-open-modal="'add-milestone'">Add Milestone</button>
        </div>
        <div class="project__milestones" :class="{ 'project__milestones--no-side': props.data.milestones.length === 0 }">
          <!-- milestone block -->
          <article v-for="milestone in descendingOrder(props.data.milestones)" :key="milestone.id" class="project__milestones__milestone" :class="{ 'project__milestones__milestone--complete': milestone.completed }">
            <div class="row">
              <div class="col-6">
                <ajax-form v-if="editingMilestoneId === milestone.id" :url="`/job/${$route.params.id}/milestone/${milestone.id}`" buttonText="Update" @done="data => milestoneUpdated(milestone, data)">
                  <template #fields>
                    <div class="form-group">
                      <label :for="`milestone-${milestone.id}-title`">Title</label>
                      <input type="text" :id="`milestone-${milestone.id}-title`" name="title" class="form-control" :value="milestone.title" placeholder="Title"/>
                    </div>
                    <div class="form-group">
                      <label :for="`milestone-${milestone.id}-due`">Due Date</label>
                      <input type="date" :id="`milestone-${milestone.id}-due`" name="due_date" class="form-control" :value="formatDateForInput(milestone.due_date)"/>
                    </div>
                    <div class="form-group">
                      <label :for="`milestone-${milestone.id}-description`">Description</label>
                      <textarea :id="`milestone-${milestone.id}-description`" name="description" rows="6" placeholder="Description" class="form-control" :value="milestone.description"></textarea>
                    </div>
                  </template>
                  <template #buttons>
                    <button type="button" class="button button--grey ml-1" @click="editingMilestoneId = null">Cancel</button>
                  </template>
                </ajax-form>
                <template v-else>
                  <div class="d-flex align-items-center justify-content-between mt-0 mb-3">
                    <div>
                      <h3 class="project__milestones__milestone__title">{{ milestone.title }}</h3>
                      <h4 class="project__milestones__milestone__subtitle">
                        <span v-if="milestone.completed" class="status-bubble status-bubble--sm status-bubble--green">Complete on {{ formatDate(milestone.completed_at) }}</span>
                        <span v-else><strong>Due</strong> {{ formatDate(milestone.due_date) }}</span>
                      </h4>
                    </div>
                    <!-- TEMP : actions -->
                    <div v-if="!milestone.completed">
                      <!-- edit -->
                      <button class="button button--icon button--grey mr-1" :aria-label="`Edit milestone named '${milestone.title}'`" title="Edit milestone" @click="editingMilestoneId = milestone.id">
                        <inline-svg :src="require('@/assets/icons/edit.svg')" stroke="currentColor" width="14" height="14"/>
                        <span>Edit</span>
                      </button>
                      <!-- delete -->
                      <button class="button button--icon button--red" :aria-label="`Delete milestone named '${milestone.title}'`" title="Delete milestone" @click="openDeleteMilestoneModal(milestone)">
                        <inline-svg :src="require('@/assets/icons/trash.svg')" stroke="currentColor" width="14" height="14"/>
                      </button>
                    </div>
                  </div>
                  <p>{{ milestone.description }}</p>
                </template>
              </div>
              <div class="col-6">
                <div class="d-flex align-items-center justify-content-between mt-0 mb-3">
                  <h3 class="mt-0 mb-0">Attached Documents</h3>
                  <button v-if="!milestone.completed" class="button button--sm" @click="openAddMilestoneDocumentModal(milestone)">Add Document</button>
                </div>
                <div class="project__milestones__milestone__documents-container">
                  <div v-for="document in milestone.documents" :key="document.document_uuid" class="project__milestones__milestone__document">
                    <div class="row align-items-center">
                      <div class="col">
                        <a :href="document.url" target="_blank" rel="noopener">
                          {{ document.title }}
                          <span v-if="document.requires_signoff" class="status-bubble status-bubble--xs"
                                :class="milestoneDocumentStates[document.signoff_status].class">
                            {{ milestoneDocumentStates[document.signoff_status].label.chatter }}
                          </span>
                        </a>
                      </div>
                      <div v-if="!milestone.completed && document.signoff_status !== 'approved'" class="col-auto project__milestones__milestone__document-actions">
                        <!-- edit -->
                        <button class="button button--icon button--grey mr-1" title="Edit document" :aria-label="`Edit document named '${document.title}' on milestone '${milestone.title}'`" @click="openEditMilestoneDocumentModal(milestone, document)">
                          <inline-svg :src="require('@/assets/icons/edit.svg')" stroke="currentColor" width="14" height="14"/>
                        </button>
                        <!-- delete -->
                        <button class="button button--icon button--red" title="Delete document" :aria-label="`Remove document named '${document.title}' from milestone '${milestone.title}'`" @click="openDeleteMilestoneDocumentModal(milestone, document)">
                          <inline-svg :src="require('@/assets/icons/trash.svg')" stroke="currentColor" width="14" height="14"/>
                        </button>
                      </div>
                    </div>
                    <div class="heading-desc">
                      <div v-if="document.chatter_document_message" class="mt-1 mb-0" v-html="document.chatter_document_message"/>
                      <p v-if="document.signoff_status === 'amends-required' && document.signoff_client_comments" class="mt-1 mb-0">
                        <strong>Client request</strong>: {{ document.signoff_client_comments }}
                      </p>
                    </div>
                  </div>
                  <!-- empty -->
                  <div v-if="milestone.documents.length === 0" class="project__milestones__milestone__document table__empty">
                    <p>No documents have been added.</p>
                  </div>
                </div>
              </div>
            </div>
            <!--<inline-svg :src="require('@/assets/spinner.svg')" stroke="currentColor" width="42" height="42" class="preloader"/>-->
          </article>
          <!-- no milestones -->
          <article v-if="props.data.milestones.length === 0" class="project__milestones__milestone project__milestones__milestone--empty">
            <p>No milestones have been added.</p>
          </article>
        </div>
      </section>
    </template>
  </promise>
</template>

<script>
import { ref, computed } from 'vue'
import { useMeta } from 'vue-meta'
import Promise from '@/components/promise.vue'
import AjaxForm from '@/components/ajax-form.vue'
import AjaxModal from '@/components/ajax-modal.vue'
import Avatar from '@/components/avatar.vue'
import Contacts from '@/components/contacts.vue'
import Wysiwyg from '@/components/wysiwyg.vue'
import Documents from '@/components/chatter/documents.vue'
import { isEmptyObject, formatDate, formatDateForInput, transformCheckboxValues } from '@/utils'
import { milestoneDocumentStates } from '@/strings'

export default {
  components: {
    Promise,
    AjaxForm,
    AjaxModal,
    Avatar,
    Contacts,
    Wysiwyg,
    Documents,
  },
  setup() {
    // TODO : use metaInfo() once https://github.com/nuxt/vue-meta/issues/699 is resolved.
    const title = ref('');
    const updateTitle = (data) => title.value = `${data.jobRef} - ${data.client.name}`;
    useMeta(computed(() => ({ title: title.value })));

    return { updateTitle };
  },
  data() {
    return {
      milestoneDocumentStates,

      // context for editing project update message
      editingProjectUpdate: false,

      // ID of the current milestone being edited
      editingMilestoneId: null,

      // context for delete milestone modal
      deleteMilestoneContext: null,

      // context for add/edit/delete milestone document modals
      addMilestoneDocumentContext: null,
      editMilestoneDocumentContext: null,
      deleteMilestoneDocumentContext: null,
    };
  },
  methods: {
    isEmptyObject, formatDate, formatDateForInput, transformCheckboxValues,
    /**
     * Magic link ajax was created
     */
    magicLinkCreated({ result }) {
      navigator.clipboard.writeText(result.magiclink);
    },
    /**
     * Project update was updated
     */
    projectUpdateUpdated(props, { formData }) {
      props.data.projectUpdate = formData.get('projectUpdate');
      props.data.projectUpdate_updated_at = Date.now();
      this.editingProjectUpdate = false;
    },
    /**
     * Project document was added
     */
    projectDocumentAdded({ data }, { document_uuid, formData }) {
      data.projectDocuments.push({
        document_uuid,
        title: formData.get('title'),
        document_type: formData.get('document_type'),
        url: formData.get('url'),
        original_filename: 'TODO', // TODO
        shareable_with_client: formData.get('shareable_with_client') == 1 ? true : false,
      });
    },
    /**
     * Project document was deleted
     */
    projectDocumentDeleted({ data }, { document_uuid }) {
      const index = data.projectDocuments.findIndex(document => document.document_uuid === document_uuid);
      if (index !== -1) {
        data.projectDocuments.splice(index, 1);
      }
    },
    /**
     * Project milestone was added
     */
    milestoneAdded(props, { result, formData }) {
      props.data.milestones.push({
        id: result.milestone_id,
        title: formData.get('title'),
        description: formData.get('description'),
        completed: false,
        due_date: formData.get('due_date'),
        documents: [],
      });
    },
    /**
     * Project milestone was updated
     */
    milestoneUpdated(milestone, { formData }) {
      milestone.title = formData.get('title');
      milestone.description = formData.get('description');
      milestone.due_date = formData.get('due_date');

      this.editingMilestoneId = null;
    },
    /**
     * Project milestone was deleted
     */
    milestoneDeleted(props) {
      const index = props.data.milestones.indexOf(this.deleteMilestoneContext);
      if (index !== -1) {
        props.data.milestones.splice(index, 1);
      }
    },
    /**
     * Project milestone document was added
     */
    milestoneDocumentAdded({ result, formData }) {
      const { milestone } = this.addMilestoneDocumentContext;

      milestone.documents.push({
        id: result.file_id,
        document_uuid: result.file_uuid,
        url: result.file_url,
        title: formData.get('title'),
        chatter_document_message: result.chatter_document_message,
      });
    },
    /**
     * Project milestone document was updated
     */
    milestoneDocumentUpdated({ result, formData }) {
      const { document } = this.editMilestoneDocumentContext;

      // TODO : because all the keys are the same, maybe some helper function which can
      //        do all of this for us by looping over the formData entries?
      document.title = formData.get('title');
      document.requires_signoff = formData.get('requires_signoff') === 'true' ? 1 : 0;
      document.signoff_client_contact_uuid = formData.get('signoff_client_contact_uuid');
      document.chatter_document_message = result.chatter_document_message;

      // TODO : if requires_signoff was changed, update the milestone signoff status too!
    },
    /**
     * Project milestone document was deleted
     */
    milestoneDocumentDeleted() {
      const { milestone, document } = this.deleteMilestoneDocumentContext;

      const index = milestone.documents.indexOf(document);
      if (index !== -1) {
        milestone.documents.splice(index, 1);
      }
    },
    /**
     * Order milestones in descending order based on their due date
     */
    descendingOrder(milestones) {
      milestones.sort((a, b) => new Date(b.due_date) - new Date(a.due_date));
      return milestones;
    },
    /**
     * Helper function to set context and open the delete milestone modal
     */
    openDeleteMilestoneModal(milestone) {
      this.deleteMilestoneContext = milestone;
      this.$nextTick(() => this.openModal('delete-milestone'));
    },
    /**
     * Helper function to set context and open the add milestone document modal
     */
    openAddMilestoneDocumentModal(milestone) {
      // NOTE : time is used to force the form-group to re-render by using it
      //        as a v-bind:key, as the radio inputs are a little wierd with
      //        form.reset();
      this.addMilestoneDocumentContext = {
        milestone,
        type: 'file',
        requiresSignoff: false,
        signOffRecipient: '',
        time: Date.now(),
      };
      this.$nextTick(() => this.openModal('add-milestone-document'));
    },
    /**
     * Helper function to set context and open the edit milestone document modal
     */
    openEditMilestoneDocumentModal(milestone, document) {
      this.editMilestoneDocumentContext = { milestone, document };
      this.$nextTick(() => this.openModal('edit-milestone-document'));
    },
    /**
     * Helper function to set context and open the delete milestone document modal
     */
    openDeleteMilestoneDocumentModal(milestone, document) {
      this.deleteMilestoneDocumentContext = { milestone, document };
      this.$nextTick(() => this.openModal('delete-milestone-document'));
    },
    /**
     * Get a list of contacts for the CC sign off select box
     */
    filteredClientContacts(contacts) {
      return contacts.filter(contact => contact.contactUUID !== this.addMilestoneDocumentContext.signOffRecipient);
    },
  },
}
</script>

<style lang="scss">
.project-milestones {
  &__milestone {
    margin: $timeline-item-margin 0;
    padding: 25px 50px;
    background-color: $timeline-item-background-colour;
    border-radius: $global-border-radius;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>