/**
 * Labels used in project template when showing milestone document bubbles
 */
export const milestoneDocumentStates = {
  'amends-required': {
    label: {
      chatter: 'Amends Required',
      client: 'Amends Requested',
    },
    class: 'status-bubble--orange',
  },
  'approved': {
    label: {
      chatter: 'Approved',
      client: 'Approved',
    },
    class: 'status-bubble--green',
  },
  'awaiting': {
    label: {
      chatter: 'Awaiting Client',
      client: 'Action Required',
    },
    class: '',
  },
};